@if (link) {
  <a
    #anchorElement
    cftAnimationState
    [disableAnimationDelay]="disableAnimationDelay"
    (animate)="showClickAnimation = $event === 'show'"
    [attr.id]="tabId"
    [routerLink]="link"
    [routerLinkActive]="activeClass"
    class="tab text-base"
    [class.tab-inverted]="inverted"
    (click)="tabClick($event)"
    [class.clicked]="showClickAnimation"
    [attr.data-testid]="tabId"
  >
    <ng-container [ngTemplateOutlet]="tabTitle"></ng-container>
  </a>
} @else {
  <button
    cftAnimationState
    (animate)="showClickAnimation = $event === 'show'"
    [attr.id]="tabId"
    class="tab text-base"
    [class.tab-inverted]="inverted"
    (click)="tabClick($event)"
    [class.active]="active"
    [class.clicked]="showClickAnimation"
    [attr.data-testid]="tabId"
  >
    <ng-container [ngTemplateOutlet]="tabTitle"></ng-container>
  </button>
}

<ng-template #tabTitle>
  <span
    class="flex h-full items-center space-x-xs rounded-t-3 px-md pb-xs pt-2xs"
    [class.bg-grey-50]="showClickAnimation && !inverted"
  >
    @if (dynamicIcon) {
      <cft-dynamic-icon [icon]="dynamicIcon"></cft-dynamic-icon>
    }
    @if (faIcon) {
      <fa-icon [icon]="faIcon"></fa-icon>
    }
    @if (text) {
      <span
        class="inline-block whitespace-nowrap"
        [ngClass]="{
          hidden: hideText || hideTextOnMobileDevices || hideTextOnTabletDevices,
          'md:inline-block': hideTextOnMobileDevices,
          'lg:inline-block': hideTextOnTabletDevices,
          'md:!hidden xl:!hidden': hideText
        }"
      >
        {{ text }}
      </span>
    }
    @if (counterValue || (showZeroNumberValues && counterValue === 0)) {
      <span
        class="inline-block h-[20px] w-[24px] rounded text-center text-sm"
        [ngClass]="{
          'bg-white': counterType === 'negative',
          'bg-president-50': counterType === 'default',
          'text-primary': counterType === 'negative' || counterType === 'default',
          'bg-vermillon-500 text-white': counterType === 'notification'
        }"
        [attr.data-testid]="'tab-counter-' + text"
      >
        {{ counterValue }}
      </span>
    }
  </span>
  <span class="tab-line" [class.tab-inverted]="inverted"></span>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
