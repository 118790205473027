<div class="relative">
  <div class="relative flex content-start gap-md overflow-x-auto overflow-y-hidden" (scroll)="scroll($event)">
    <div class="absolute top-full mt-[-2px] h-px w-full bg-grey-200"></div>
    <ng-content select="cft-tab"></ng-content>
  </div>
  @if (selectedTab?.templateRef; as selectedTabContent) {
    <div>
      <ng-container *ngTemplateOutlet="selectedTabContent"></ng-container>
    </div>
  }
</div>
