import {EventEmitter, TemplateRef} from '@angular/core'

export abstract class Tab {
  /**
   * A unique identifier for the tab to identify it in the parent component.
   */
  tabId?: string
  abstract templateRef: TemplateRef<unknown>
  /**
   * active state of the tab
   * should always be set by the parent component, because it knows all the tabs
   */
  abstract active: boolean
  abstract tabActivated: EventEmitter<boolean>
}
